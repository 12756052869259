@import url("https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;700&family=Fira+Sans:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-size: 18px;
}

body {
}

/* headers */

.h1-base {
    @apply font-mono font-bold text-3xl;
}

.h1 {
    @apply h1-base;

    &:before {
        content: "⸺";
        @apply text-cyan-300;
    }
}

.h1-alt {
    @apply h1-base;

    &:before {
        content: "⸺";
        @apply text-cyan-500;
    }
}

.h2 {
    @apply font-mono font-bold text-xl;
}

.h3 {
    @apply font-mono font-bold;
}

.footer-h3 {
    @apply font-mono text-sm uppercase text-cyan-100;
}

/* links */

.nav-link {
    @apply
    font-mono uppercase leading-none
    bg-cyan-200 hover:bg-cyan-50 active:bg-cyan-100
    transition;
}

.text-link {
    @apply
    leading-none
    border-b-4 border-cyan-300
    hover:text-cyan-600 active:text-cyan-800
    transition;
}

.text-link-alt {
    @apply
    leading-none
    border-b-4 border-white
    hover:bg-cyan-50 active:bg-cyan-100
    transition;
}

.footer-text-link {
    @apply
    leading-none
    border-b-4 border-transparent hover:border-cyan-800
    hover:text-cyan-200 active:text-cyan-300
    transition;
}

.footer-image-link {
    @apply hover:text-cyan-200 active:text-cyan-300 transition;
}

/* layout */

.content-container {
    @apply container px-4 mx-auto;
}

.layout-2-cols {
    @apply grid grid-cols-1 lg:grid-cols-2 gap-12;
}

.layout-3-cols {
    @apply grid grid-cols-1 lg:grid-cols-3 gap-12;
}

.layout-4-cols {
    @apply grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-12 gap-y-8;
}

/* buttons */

.primary-button {
    @apply relative;
}

.primary-button-content {
    @apply
    p-4
    flex items-center
    text-xl font-mono uppercase text-cyan-900
    border-2 border-cyan-900
    bg-white active:bg-cyan-50
    transition
    transform hover:-translate-x-1 hover:-translate-y-1
    relative z-10;
}

.primary-button-shadow {
    @apply
    absolute inset-0
    transform translate-x-2 translate-y-2
    bg-repeat;
    background-image: url("../images/patterns/shadow-stripes.svg");
}

.primary-button-icon {
    @apply
    p-4
    text-cyan-700
    bg-cyan-100
    border-2 border-cyan-200;
}

.secondary-button {
    @apply
    px-6 py-2
    font-mono uppercase text-cyan-900
    border-2 border-cyan-900
    bg-white hover:bg-cyan-50 active:bg-cyan-100
    transition;
}

/* utils */

.stroke-round {
    stroke-linecap: round;
}

/* hero */

header {
    background-image: url("../images/patterns/wiggle-hero.svg");
    background-repeat: repeat;
    /* this is needed on mobile to truncate the background shapes */
    overflow-x: clip;
}

#download {
    background-image: url("../images/patterns/shapes.svg");
    background-repeat: repeat;
}

/* tools */

.tool-tab {
    @apply font-mono text-sm uppercase text-cyan-900 border-2 border-cyan-900 bg-white hover:bg-cyan-50 active:bg-cyan-100 transition px-4 pt-1.5 pb-1;

    &.selected {
        @apply bg-cyan-200;
    }
}

.tool-li {
    @apply text-yellow-900 bg-yellow-100 px-4 py-1;
}

.tool-group {
    /*animation: slideIn 300ms cubic-bezier(0, 0, 0.2, 1);*/
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(8rem);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* features */

.feature {
    @apply relative flex;
}

.feature-content {
    @apply
    flex-1
    p-8
    flex flex-col items-start gap-4
    bg-white
    relative z-20;
}

.feature-shadow {
    @apply
    absolute inset-0
    bg-cyan-100
    transform translate-x-2 translate-y-2
    z-10;
}

.features-icon-container {
    @apply
    p-4
    text-cyan-500
        /*border-2 border-cyan-200*/
    bg-cyan-50;
}

#features-section-background {
    background: repeating-linear-gradient(
            -45deg,
            #CFFAFE,
            #CFFAFE 8px, transparent 8px,
            transparent 16px
    );
}

/* downloads */

.download-notes-ul {
    @apply flex flex-col gap-1;
}

.download-notes-li {
    @apply relative;

    &:before {
        content: "⸺";
        @apply text-cyan-500 font-bold absolute -left-8;
    }
}

/* footer */

footer {
    background-image: url("../images/patterns/wiggle-footer.svg");
    background-repeat: repeat;
}
